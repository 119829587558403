/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldType, Section } from "../graphQLTypes";
import {
  IAnswer,
  IFieldAnswer,
  IFieldValue,
  IFormFields,
  IQuestion
} from "../types";
import { buildFieldKey } from "./buildFieldKey";
import { convertDateTimeToDateString } from "./convertDateTimeToDateString";
import { convertDateStringToDateTime } from "./convertDateStringToDateTime";
import { isGeneralUploadField } from "src/utils/answers";

interface IBuildAnswersInputProps {
  answers: IAnswer[];
  questionId: string;
  data: IFormFields;
  isTimelineSection?: boolean;
}

interface IBuildFieldsInputProps {
  fields: IFieldAnswer[];
  questionId: string;
  answerId: string;
  data: IFormFields;
  isTimelineSection?: boolean;
}

export type DraftAnswerVales = {
  [fieldId: string]: any;
};

export type DraftQuestionValues = {
  [answerId: string]: {
    isDeleted?: boolean;
    isAnswered?: boolean;
    index: ScalarLong;
    fields: DraftAnswerVales;
  };
};

export type DraftQuestionValue = {
  isVisible: boolean;
  answers: DraftQuestionValues;
};

export type DraftSectionValues = {
  [questionId: string]: DraftQuestionValue;
};

export type DraftValues = {
  [section in Section]?: DraftSectionValues;
};

const setValueByFieldType = (
  type: FieldType,
  value: IFieldValue,
  isMulti: boolean
): any => {
  switch (type) {
    case FieldType.STRING:
    case FieldType.EMAIL:
    case FieldType.PHONE_NUMBER:
    case FieldType.HIRING_MANAGER_EMAIL:
    case FieldType.LABEL:
    case FieldType.NATIONAL_INSURANCE_NUMBER:
    case FieldType.PASSPORT:
    case FieldType.SURNAME:
    case FieldType.FIRST_NAME:
    case FieldType.DRIVING_LICENCE_NUMBER:
      return value || null;

    case FieldType.SELECT:
      if (isMulti) {
        return value || null;
      }
      return value && value.length ? value : null;

    case FieldType.MULTIPLE_STRING:
      return value && value.length ? value : null;

    case FieldType.QUALIFICATIONS_AND_GRADES:
      return value && value.length ? value : null;

    case FieldType.BOOLEAN:
    case FieldType.CONTACT_EMPLOYER:
      return value;

    case FieldType.INTEGER:
      return Number(value);

    case FieldType.DATE:
      return value
        ? convertDateStringToDateTime(convertDateTimeToDateString(value))
        : null;

    case FieldType.COUNTRY:
      return value || null;

    case FieldType.DATE_RANGE:
      if (!value || (value && !value.from && !value.to)) {
        return null;
      }
      return {
        from: value.from ? new Date(value.from).valueOf() : null,
        to: value.to ? new Date(value.to).valueOf() : null
      };

    case FieldType.ADDRESS:
      return value || null;

    case FieldType.COMPANY:
      return value || null;

    default:
      return null;
  }
};

const buildFieldsInput = ({
  fields,
  questionId,
  answerId,
  data,
  isTimelineSection
}: IBuildFieldsInputProps): DraftAnswerVales =>
  fields.reduce(
    (acc: DraftAnswerVales, field: IFieldAnswer): DraftAnswerVales => {
      const fieldKey: string = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });

      const fieldValue = isTimelineSection ? field.value : data[fieldKey];

      if (
        (!fieldValue && fieldValue !== false && !field.isAnswered) ||
        isGeneralUploadField(field.type)
      ) {
        return acc;
      }

      return {
        ...acc,
        [field.fieldId]: setValueByFieldType(
          field.type,
          fieldValue,
          field.isMulti
        )
      };
    },
    []
  );

const buildAnswersInput = ({
  answers,
  questionId,
  data,
  isTimelineSection
}: IBuildAnswersInputProps): DraftQuestionValues =>
  answers.reduce(
    (acc: DraftQuestionValues, answer: IAnswer): DraftQuestionValues => {
      const fields: DraftAnswerVales = buildFieldsInput({
        fields: answer.fields.filter(
          (field: IFieldAnswer) => !isGeneralUploadField(field.type)
        ),
        questionId,
        answerId: answer.answerId,
        data,
        isTimelineSection
      });

      if (!Object.keys(fields).length) {
        return acc;
      }

      return {
        ...acc,
        [answer.answerId]: {
          index: answer.index,
          isAnswered: answer.isAnswered,
          isDeleted: answer.isDeleted,
          fields
        }
      };
    },
    {}
  );

interface IBuildInputForSaveDraftValuesProps {
  data: IFormFields;
  questions: IQuestion[];
  isTimelineSection?: boolean;
  section: Section;
}

export const buildInputForSaveDraftValues = ({
  isTimelineSection = false,
  questions,
  data,
  section
}: IBuildInputForSaveDraftValuesProps): DraftValues => ({
  [section]: questions.reduce(
    (acc: DraftSectionValues, question: IQuestion): DraftSectionValues => {
      const answers: DraftQuestionValues = buildAnswersInput({
        answers: question.answers,
        questionId: question.questionId,
        data,
        isTimelineSection
      });

      if (!Object.keys(answers).length) {
        return acc;
      }

      return {
        ...acc,
        [question.questionId]: {
          answers,
          isVisible: question.isVisible
        }
      };
    },
    {}
  )
});
