import { useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { attachmentFragment } from "src/graphQLTypes";
import { downloadFile } from "src/utils";
import { headerCustomLogoUrl } from "src/cache";

type UseCustomLogoImage = {
  imageUrl: string | null;
};

export const useCustomLogoImage = (
  file?: attachmentFragment | null
): UseCustomLogoImage => {
  const customLogoUrl = useReactiveVar(headerCustomLogoUrl);

  const downloadImage = useCallback(async () => {
    if (!file) {
      headerCustomLogoUrl(null);
      return;
    }

    const blob = await downloadFile(file, true);

    const objectUrl = blob ? URL.createObjectURL(blob) : null;

    headerCustomLogoUrl(objectUrl);
  }, [file]);

  useEffect(() => {
    if (!customLogoUrl) {
      downloadImage();
    }
  }, [customLogoUrl, downloadImage]);

  return { imageUrl: customLogoUrl };
};
