import {
  AddressInput,
  AnswerDetailsInput,
  AnswerInput,
  caseUpdateVariables,
  FieldType,
  FieldValueInput
} from "../graphQLTypes";
import {
  IAddressValue,
  IAnswer,
  IFieldAnswer,
  IFieldValue,
  IFormFields,
  IQuestion
} from "../types";
import { buildFieldKey } from "./buildFieldKey";
import { convertDateTimeToDateString } from "./convertDateTimeToDateString";
import { NAME_ALLOWED_SPECIAL_CHARACTER } from "src/constants";
import { isGeneralUploadField, isMultiUploadField } from "src/utils/answers";

interface IBuildAnswersInputProps {
  answers: IAnswer[];
  questionId: string;
  data: IFormFields;
  isTimelineSection?: boolean;
}

interface IBuildFieldsInputProps {
  fields: IFieldAnswer[];
  questionId: string;
  answerId: string;
  data: IFormFields;
  isTimelineSection?: boolean;
}

const getTrimmedName = (value: string): string => {
  if (value.includes(NAME_ALLOWED_SPECIAL_CHARACTER)) {
    return value
      .split(NAME_ALLOWED_SPECIAL_CHARACTER)
      .map((str) => str.trim())
      .join(NAME_ALLOWED_SPECIAL_CHARACTER);
  }
  return value.trim().replace(/\s+/g, " ");
};

const getAddressToSave = (address: IAddressValue): AddressInput => {
  return {
    addressLine1: address?.addressLine1
      ? address?.addressLine1.trim()
      : address?.addressLine1 || "",
    addressLine2: address?.addressLine2 ? address?.addressLine2.trim() : null,
    addressLine3: address?.addressLine3 ? address?.addressLine3.trim() : null,
    countryId: address?.country.id,
    county: address?.county ? address?.county.trim() : address?.county,
    postcode: address?.postcode
      ? address.postcode.trim().toUpperCase()
      : address.postcode || "",
    town: address?.town ? address?.town.trim() : address?.town || ""
  };
};

const getMultipleStringValueToSave = (value: string[]): string[] | null => {
  return value?.length ? value.map((val) => val.trim()) : null;
};

export const setValueByFieldType = (
  type: FieldType,
  value: IFieldValue,
  isMulti: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  switch (type) {
    case FieldType.STRING:
    case FieldType.EMAIL:
    case FieldType.PHONE_NUMBER:
    case FieldType.HIRING_MANAGER_EMAIL:
    case FieldType.DRIVING_LICENCE_NUMBER:
    case FieldType.LABEL:
      return {
        stringValue: (typeof value === "string" ? value.trim() : value) || null
      };
    case FieldType.NATIONAL_INSURANCE_NUMBER:
      return {
        stringValue:
          (typeof value === "string" ? value.trim().toUpperCase() : value) ||
          null
      };
    case FieldType.FIRST_NAME:
    case FieldType.SURNAME:
      return {
        stringValue:
          (typeof value === "string" ? getTrimmedName(value) : value) || null
      };

    case FieldType.PASSPORT:
      return { stringValue: value?.toUpperCase() || null };

    case FieldType.SELECT:
      return isMulti
        ? { stringValues: value && value.length ? value : null }
        : { stringValue: value || null };

    case FieldType.MULTIPLE_STRING:
      return { stringValues: getMultipleStringValueToSave(value) };

    case FieldType.QUALIFICATIONS_AND_GRADES:
      return {
        qualificationsAndGradesValue: {
          qualificationsAndGrades:
            value && value.length
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                value.map(({ qualification, grades }: any) => ({
                  qualification,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  grades: grades.map(({ grade, subject }: any) => ({
                    grade,
                    subject
                  }))
                }))
              : null
        }
      };

    case FieldType.BOOLEAN:
    case FieldType.CONTACT_EMPLOYER:
      return { booleanValue: value };

    case FieldType.INTEGER:
      return { floatValue: Number(value) };

    case FieldType.DATE:
      return { dateValue: value ? convertDateTimeToDateString(value) : null };

    case FieldType.COUNTRY:
      return { countryValue: value ? { id: value.id } : null };

    case FieldType.DATE_RANGE:
      if (!value || (value && !value.from && !value.to)) {
        return { dateRangeValue: null };
      }
      return {
        dateRangeValue: {
          from: value.from ? convertDateTimeToDateString(value.from) : null,
          to: value.to ? convertDateTimeToDateString(value.to) : null
        }
      };

    case FieldType.ADDRESS:
      if (!value) {
        return { addressValue: null };
      }
      return {
        addressValue: getAddressToSave(value)
      };

    case FieldType.COMPANY:
      if (!value) {
        return { companyValue: null };
      }
      return {
        companyValue: {
          name: value.name ? value.name.trim() : value.name,
          address: getAddressToSave(value)
        }
      };
    default:
      return undefined;
  }
};

const buildFieldsInput = ({
  fields,
  questionId,
  answerId,
  data,
  isTimelineSection
}: IBuildFieldsInputProps): FieldValueInput[] =>
  fields.reduce(
    (acc: FieldValueInput[], field: IFieldAnswer): FieldValueInput[] => {
      if (isGeneralUploadField(field.type) && !field.isVisible && field.value) {
        return [
          ...acc,
          {
            fieldId: field.fieldId,
            ...(isMultiUploadField(field.type)
              ? { uploadIndexes: [-1] }
              : { uploadIndex: -1 })
          }
        ];
      }
      const fieldKey: string = buildFieldKey({
        questionId,
        answerId,
        fieldId: field.fieldId,
        type: field.type,
        fieldType: field.fieldType,
        isMulti: field.isMulti
      });
      const fieldValue = isTimelineSection ? field.value : data[fieldKey];
      if (
        (!fieldValue && fieldValue !== false && !field.isAnswered) ||
        isGeneralUploadField(field.type)
      ) {
        return [...acc];
      }
      return [
        ...acc,
        {
          fieldId: field.fieldId,
          ...setValueByFieldType(field.type, fieldValue, field.isMulti)
        }
      ];
    },
    []
  );

export const buildAnswersInput = ({
  answers,
  questionId,
  data,
  isTimelineSection
}: IBuildAnswersInputProps): AnswerDetailsInput[] =>
  answers.reduce(
    (acc: AnswerDetailsInput[], answer: IAnswer): AnswerDetailsInput[] => {
      if (answer.isDeleted && !answer.isAnswered) {
        return [...acc];
      }
      if (answer.isDeleted && answer.isAnswered) {
        return [
          ...acc,
          {
            id: answer.answerId,
            fields: []
          }
        ];
      }
      const fields: FieldValueInput[] = buildFieldsInput({
        fields: answer.fields,
        questionId,
        answerId: answer.answerId,
        data,
        isTimelineSection
      });
      if (!fields.length) {
        return [...acc];
      }
      return [
        ...acc,
        {
          ...(answer.isAnswered ? { id: answer.answerId } : {}),
          fields
        }
      ];
    },
    []
  );

export const buildInputForCaseUpdate = (
  data: IFormFields,
  questions: IQuestion[],
  caseId: string,
  isTimelineSection: boolean = false
): caseUpdateVariables => ({
  files: [],
  input: {
    consentGiven: false,
    caseId,
    answers: questions.reduce(
      (acc: AnswerInput[], question: IQuestion): AnswerInput[] => {
        const answers: AnswerDetailsInput[] = buildAnswersInput({
          answers: question.answers,
          questionId: question.questionId,
          data,
          isTimelineSection
        });
        if (!answers.length) {
          return [...acc];
        }
        return [
          ...acc,
          {
            questionId: question.questionId,
            answers
          }
        ];
      },
      []
    )
  }
});
