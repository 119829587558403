import { FieldErrors } from "react-hook-form";
import { formatDate } from "@security-watchdog/sw-ui-kit";
import * as yup from "yup";
import { isBefore } from "date-fns";
import {
  FieldType,
  getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction,
  getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction,
  HandleableFieldType
} from "../graphQLTypes";
import {
  IBuildFieldKeyProps,
  ICompanyValue,
  IFieldsValidationMap,
  IFormFields,
  ITimeLineRestriction,
  IValidation,
  ErrorValidationType,
  IAddressValue
} from "../types";
import { buildFieldKey, parseFieldKey } from "./buildFieldKey";
import { convertDateStringToDateTime } from "./convertDateStringToDateTime";
import { getFormErrorMessage } from "./getFormErrorMessage";
import { validatesNumbersByCompOperator } from "./validatesNumbersByCompOperator";
import {
  ADDRESS_COUNTRY_VALIDATION_RULES,
  ADDRESS_COUNTY_VALIDATION_RULES,
  ADDRESS_LINE1_VALIDATION_RULES,
  ADDRESS_LINE_VALIDATION_RULES,
  ADDRESS_POSTCODE_VALIDATION_RULES,
  ADDRESS_TOWN_VALIDATION_RULES,
  COMPANY_NAME_VALIDATION_RULES,
  EMAIL_VALIDATION_RULES,
  FREE_TEXT_VALIDATION_RULES,
  INSURANCE_VALIDATION_RULES,
  INTEGER_VALIDATION_RULES,
  PASSPORT_VALIDATION_RULES,
  PHONE_VALIDATION_RULES,
  NAME_VALIDATION_RULES,
  MULTI_STRING_VALIDATION_RULES,
  JOB_TITLE_VALIDATION_RULES,
  FREE_TEXT_MAX_LENGTH,
  JOB_TITLE_MAX_LENGTH,
  NAME_MAX_LENGTH,
  DRIVING_LICENSE_NUMBER_VALIDATION_RULES
} from "src/constants/form.validation";
import {
  AddressFormField,
  AddressFormFieldValidation,
  CompanyFormField,
  CompanyFormFieldValidation,
  FileForValidation
} from "src/types/index";
import { FieldError } from "react-hook-form/dist/types/errors";
import { nonNullable } from "src/utils/typeGuards";
import { REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE } from "@security-watchdog/ui-form-validation";

export const getYupErrorMessageForField = (
  value: string | FileForValidation[],
  rule: yup.StringSchema | yup.NumberSchema | yup.AnySchema,
  error?: string
): string => {
  try {
    rule.validateSync(value);
  } catch (validationError) {
    if (
      validationError instanceof yup.ValidationError &&
      validationError.message
    ) {
      return error || validationError.message;
    }
  }
  return "";
};

export const getYupErrorForField = (
  value: string | FileForValidation | FileForValidation[],
  rule: yup.StringSchema | yup.NumberSchema | yup.AnySchema
): yup.ValidationError | null => {
  try {
    rule.validateSync(value, { abortEarly: false });
  } catch (validationError) {
    if (validationError instanceof yup.ValidationError) {
      return validationError;
    }
  }
  return null;
};

const getValidationForAddressField = (
  value: IAddressValue
): AddressFormFieldValidation | null => {
  const validation = {} as AddressFormFieldValidation;

  const postcodeError = getYupErrorMessageForField(
    value?.postcode || "",
    ADDRESS_POSTCODE_VALIDATION_RULES
  );
  const addressLine1Error = getYupErrorMessageForField(
    value?.addressLine1 || "",
    ADDRESS_LINE1_VALIDATION_RULES
  );

  const addressLine2Error = getYupErrorMessageForField(
    value?.addressLine2 || "",
    ADDRESS_LINE_VALIDATION_RULES
  );
  const addressLine3Error = getYupErrorMessageForField(
    value?.addressLine3 || "",
    ADDRESS_LINE_VALIDATION_RULES
  );
  const townError = getYupErrorMessageForField(
    value?.town || "",
    ADDRESS_TOWN_VALIDATION_RULES
  );
  const countryError = getYupErrorMessageForField(
    value?.country?.name || "",
    ADDRESS_COUNTRY_VALIDATION_RULES
  );
  const countyError = getYupErrorMessageForField(
    value?.county || "",
    ADDRESS_COUNTY_VALIDATION_RULES
  );

  if (postcodeError) {
    validation[AddressFormField.POST_CODE] = postcodeError;
  }

  if (countryError) {
    validation[AddressFormField.COUNTRY] = countryError;
  }

  if (countyError) {
    validation[AddressFormField.COUNTY] = countyError;
  }

  if (addressLine1Error) {
    validation[AddressFormField.ADDRESS_LINE_1] = addressLine1Error;
  }

  if (addressLine2Error) {
    validation[AddressFormField.ADDRESS_LINE_2] = addressLine2Error;
  }

  if (addressLine3Error) {
    validation[AddressFormField.ADDRESS_LINE_3] = addressLine3Error;
  }

  if (townError) {
    validation[AddressFormField.TOWN] = townError;
  }

  return Object.keys(validation)?.length ? validation : null;
};

const getErrorMessageFromNumericRestrictions = (
  value: string,
  numericRestrictions: getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction[]
): string => {
  let errorMessage = "";

  numericRestrictions.forEach((numericRestriction) => {
    if (
      numericRestriction.numericValue !== undefined &&
      numericRestriction.numericValue !== null &&
      !validatesNumbersByCompOperator(
        numericRestriction.comp,
        Number(value),
        numericRestriction.numericValue
      )
    ) {
      errorMessage = getFormErrorMessage(
        numericRestriction.comp,
        numericRestriction.numericValue
      );
    }
  });

  return errorMessage;
};

const getErrorMessageForAddressField = (value: IAddressValue): string => {
  const validation = getValidationForAddressField(value);

  return validation ? JSON.stringify(validation) : "";
};

const getErrorMessageForCompanyField = (value: ICompanyValue): string => {
  let validation = {} as CompanyFormFieldValidation;

  const companyNameError = getYupErrorMessageForField(
    value?.name || "",
    COMPANY_NAME_VALIDATION_RULES.required(
      REQUIRED_FIELD_VALIDATION_ERROR_MESSAGE
    )
  );
  const addressValidation = getValidationForAddressField(value);

  if (companyNameError) {
    validation[CompanyFormField.NAME] = companyNameError;
  }

  if (addressValidation) {
    validation = { ...validation, ...addressValidation };
  }

  return Object.keys(validation)?.length ? JSON.stringify(validation) : "";
};

const getErrorMessageForLengthRestriction = (
  value: string,
  minLength: number | undefined,
  maxLength: number | undefined
): string => {
  if (minLength && value.length < minLength) {
    return getFormErrorMessage("minLength", minLength);
  }
  if (maxLength && value.length > maxLength) {
    return getFormErrorMessage("maxLength", maxLength);
  }
  return "";
};

const getErrorMessageForEmailField = (
  value: string,
  lengthRestriction:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction
    | undefined
): string => {
  const emailError = getYupErrorMessageForField(value, EMAIL_VALIDATION_RULES);

  return emailError
    ? emailError
    : getErrorMessageForLengthRestriction(
        value,
        lengthRestriction?.minLength || undefined,
        lengthRestriction?.maxLength || undefined
      );
};

const getErrorMessageForIntegerField = (
  value: string,
  numericRestrictions:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction[]
    | undefined
): string => {
  const integerValidationError = getYupErrorMessageForField(
    value,
    INTEGER_VALIDATION_RULES
  );

  if (integerValidationError) {
    return integerValidationError;
  }

  if (numericRestrictions) {
    return getErrorMessageFromNumericRestrictions(value, numericRestrictions);
  }
  return "";
};

const getErrorMessageForDateField = (
  value: Date,
  dateRestrictions?: getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction[]
): string => {
  if (dateRestrictions) {
    let errorMessage = "";
    dateRestrictions.forEach(
      (
        dateRestriction: getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction
      ) => {
        const convertedDateString = convertDateStringToDateTime(
          dateRestriction.dateValue
        );

        const validatedNumber =
          convertedDateString &&
          !validatesNumbersByCompOperator(
            dateRestriction.comp,
            value.valueOf(),
            convertedDateString
          );

        if (
          dateRestriction.dateValue !== undefined &&
          dateRestriction.dateValue !== null &&
          validatedNumber
        ) {
          errorMessage = getFormErrorMessage(
            dateRestriction.comp,
            formatDate(convertDateStringToDateTime(dateRestriction.dateValue))
          );
        }
      }
    );
    return errorMessage;
  }
  return "";
};

const getErrorMessageForDateRangeField = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  isEndDateRequired: boolean,
  timeLineRestriction?: ITimeLineRestriction
): string => {
  if (!value || (!value.from && !value.to)) {
    return `${getFormErrorMessage("required")}|${
      isEndDateRequired ? getFormErrorMessage("required") : ""
    }`;
  }

  if (timeLineRestriction && !isEndDateRequired) {
    const isDateValid = value.from && isBefore(value.from, new Date());

    return isDateValid ? "" : `${getFormErrorMessage("validTimelineEnd")}`;
  }

  if ((value && !value.from) || !value.to) {
    return `${value.from ? "" : getFormErrorMessage("required")}${
      !value.to && isEndDateRequired
        ? `|${getFormErrorMessage("required")}`
        : ""
    }`;
  }

  if (
    value &&
    value.from &&
    value.to &&
    isEndDateRequired &&
    value.from.valueOf() > value.to.valueOf()
  ) {
    return `|${getFormErrorMessage("validEndDate")}`;
  }

  if (timeLineRestriction && isEndDateRequired) {
    const restrictedByStartDate =
      value.from.valueOf() > timeLineRestriction.endDate.valueOf()
        ? getFormErrorMessage("validTimelineEnd")
        : "";

    const restrictedByEndDate =
      // eslint-disable-next-line no-nested-ternary
      value.to.valueOf() > timeLineRestriction.endDate.valueOf()
        ? getFormErrorMessage("validTimelineEnd")
        : value.to.valueOf() < timeLineRestriction.startDate.valueOf()
          ? getFormErrorMessage("validTimelineStart")
          : "";

    if (restrictedByStartDate || restrictedByEndDate) {
      return `${restrictedByStartDate}|${restrictedByEndDate}`;
    }
  }

  return "";
};

const getErrorMessageForPassportField = (value: string): string =>
  getYupErrorMessageForField(value, PASSPORT_VALIDATION_RULES);

const getErrorMessageForNINumberField = (value: string): string =>
  getYupErrorMessageForField(value, INSURANCE_VALIDATION_RULES);

const getErrorMessageForPhoneField = (value: string): string =>
  getYupErrorMessageForField(value, PHONE_VALIDATION_RULES);

const getErrorMessageForDriverLicenceField = (value: string): string =>
  getYupErrorMessageForField(value, DRIVING_LICENSE_NUMBER_VALIDATION_RULES);

const getErrorMessageForFreeTextField = (
  value: string,
  lengthRestriction?:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction
    | undefined
) => {
  const freeTextError = getYupErrorMessageForField(
    value,
    FREE_TEXT_VALIDATION_RULES
  );

  return freeTextError
    ? freeTextError
    : getErrorMessageForLengthRestriction(
        value,
        lengthRestriction?.minLength || undefined,
        lengthRestriction?.maxLength || FREE_TEXT_MAX_LENGTH
      );
};

const getErrorMessageForJobTitleField = (
  value: string,
  lengthRestriction?:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction
    | undefined
) => {
  const jobTitleError = getYupErrorMessageForField(
    value,
    JOB_TITLE_VALIDATION_RULES
  );

  return jobTitleError
    ? jobTitleError
    : getErrorMessageForLengthRestriction(
        value,
        lengthRestriction?.minLength || undefined,
        lengthRestriction?.maxLength || JOB_TITLE_MAX_LENGTH
      );
};

const getErrorMessageForStringField = (
  value: string,
  fieldType: HandleableFieldType,
  lengthRestriction?:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction
    | undefined
) => {
  if (fieldType === HandleableFieldType.CURRENT_POSITION) {
    return getErrorMessageForJobTitleField(value, lengthRestriction);
  }
  return getErrorMessageForFreeTextField(value, lengthRestriction);
};

const getErrorMessageForMultiLineTextField = (value: string) =>
  getYupErrorMessageForField(value, MULTI_STRING_VALIDATION_RULES);

const getErrorMessageForUserNameField = (
  value: string,
  lengthRestriction?:
    | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction
    | undefined
) => {
  const userNameError = getYupErrorMessageForField(
    value,
    NAME_VALIDATION_RULES
  );

  return userNameError
    ? userNameError
    : getErrorMessageForLengthRestriction(
        value,
        lengthRestriction?.minLength || undefined,
        lengthRestriction?.maxLength || NAME_MAX_LENGTH
      );
};

const getErrorMessageForMultipleStringField = (
  value: string[],
  isRequired: boolean
): Array<FieldError | undefined> => {
  const requiredError = {
    type: ErrorValidationType.ERROR_TYPE,
    message: getFormErrorMessage("required")
  };

  if (isRequired && !value) {
    return [requiredError];
  }

  return value?.map((val: string) => {
    if (!val && isRequired) {
      return requiredError;
    }
    const textLineValidationError = getErrorMessageForMultiLineTextField(val);
    return textLineValidationError
      ? {
          type: ErrorValidationType.ERROR_TYPE,
          message: textLineValidationError
        }
      : undefined;
  });
};

export const validationResolver = (
  values: IFormFields,
  fieldsValidationMap: IFieldsValidationMap
): { values: IFormFields | {}; errors: FieldErrors<{}> | {} } => {
  const errors: FieldErrors<IFormFields> = {};

  Object.keys(values).forEach((key: string) => {
    const {
      questionId,
      answerId,
      fieldId,
      type,
      fieldType
    }: IBuildFieldKeyProps = parseFieldKey(key);

    const {
      isMulti,
      isRequired,
      lengthRestriction,
      numericRestrictions,
      dateRestrictions,
      dependentField,
      timeLineRestriction
    }: IValidation =
      fieldsValidationMap[decodeURI(`${questionId}${fieldId}`)] || {};
    const value = values[key];

    if (type === FieldType.PHONE_NUMBER) {
      const errorMessage = getErrorMessageForPhoneField(value);

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.DRIVING_LICENCE_NUMBER) {
      const errorMessage = getErrorMessageForDriverLicenceField(value);

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.FIRST_NAME && value) {
      const errorMessage = getErrorMessageForUserNameField(
        value,
        lengthRestriction
      );

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.SURNAME && value) {
      const errorMessage = getErrorMessageForUserNameField(
        value,
        lengthRestriction
      );

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (
      (isRequired && !value && value !== false) ||
      (isRequired && isMulti && value && !value.length)
    ) {
      errors[key] = {
        type: ErrorValidationType.ERROR_TYPE,
        message: getFormErrorMessage("required")
      };
    }

    if (type === FieldType.MULTIPLE_STRING) {
      const multipleStringErrors = getErrorMessageForMultipleStringField(
        value,
        isRequired
      );

      if (multipleStringErrors?.filter(nonNullable).length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errors[key] = multipleStringErrors as any;
      }
    }

    if (type === FieldType.ADDRESS) {
      const errorMessage = getErrorMessageForAddressField(value);
      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.COMPANY) {
      const errorMessage = getErrorMessageForCompanyField(value);
      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (
      [FieldType.EMAIL, FieldType.HIRING_MANAGER_EMAIL].includes(type) &&
      value
    ) {
      const errorMessage: string = getErrorMessageForEmailField(
        value,
        lengthRestriction
      );
      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.INTEGER && value) {
      const errorMessage: string = getErrorMessageForIntegerField(
        value,
        numericRestrictions
      );
      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.DATE_RANGE) {
      const dependentFieldKey: string =
        (dependentField &&
          buildFieldKey({
            questionId,
            answerId,
            fieldId: dependentField.fieldId,
            type: dependentField.type,
            fieldType: dependentField.fieldType,
            isMulti: dependentField.isMulti
          })) ||
        "";

      const isEndDateRequired = Boolean(!values[dependentFieldKey]);

      const errorMessage: string = getErrorMessageForDateRangeField(
        value,
        isEndDateRequired,
        timeLineRestriction
      );

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }

    if (type === FieldType.STRING && value) {
      const errorMessage: string = getErrorMessageForStringField(
        value,
        fieldType,
        lengthRestriction
      );

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.DATE && value) {
      const errorMessage: string = getErrorMessageForDateField(
        value,
        dateRestrictions
      );

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.NATIONAL_INSURANCE_NUMBER) {
      const errorMessage = getErrorMessageForNINumberField(value);

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
    if (type === FieldType.PASSPORT) {
      const errorMessage = getErrorMessageForPassportField(value);

      if (errorMessage) {
        errors[key] = {
          type: ErrorValidationType.ERROR_TYPE,
          message: errorMessage
        };
      }
    }
  });

  return {
    values,
    errors
  };
};
